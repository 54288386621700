import { Text } from '@dsx/react';
import styles from './Footer.module.css';

export function Footer() {
	const currentYear = new Date().getFullYear();
	return (
		<footer className={styles['footer']}>
			<Text>
				© 2020 - {currentYear} Direct Supply Senior Living Advocacy, Inc. All
				rights reserved.
			</Text>
		</footer>
	);
}
