import {
	config,
	EditablePage,
	EnvironmentContextProvider,
	ProvideAll,
} from '@ds-mgnl/marketing';
import { useLoaderData } from 'react-router-dom';
import type { LoaderFunction } from 'react-router-dom';

export const magnoliaPageLoader: LoaderFunction = async ({ request }) =>  {
	const url = new URL(request.url);
	//const rconfig = {headers: {'X-Mgnl-Age': 'Child'}};

	const pathRoot = url.searchParams.has('cmsPreview')
		? process.env.REACT_APP_MGNL_BASE_AUTHOR
		: process.env.REACT_APP_MGNL_BASE_PUBLIC;
	const fullContentPath =
		pathRoot +
		process.env.REACT_APP_MGNL_API_PAGES! +
		process.env.REACT_APP_MGNL_APP_BASE +
		url.pathname;

	//const pageResponse = await fetch(fullContentPath, rconfig);
	const pageResponse = await fetch(fullContentPath);
	if (pageResponse.status != 200) {
		throw new Error(pageResponse.statusText);
	}
	const pageJson = await pageResponse.json();
	return { pageJson };
}

export function MagnoliaPage() {
	const { pageJson } = useLoaderData() as any;
	return (
		<ProvideAll>
			<EnvironmentContextProvider
				value={{
					damHost: process.env.REACT_APP_MGNL_HOST!,
					appHost: process.env.REACT_APP_MGNL_HOST!,
					isInMagnolia: false,
				}}
			/>
			<EditablePage
				templateAnnotations={{}}
				content={pageJson}
				config={config}
			></EditablePage>
		</ProvideAll>
	);
}
