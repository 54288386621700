import { render } from 'react-dom';
import { App } from './App';
import '@dsx/react/theme-default';
import '@dsx/css-utils';
import '@ds-mgnl/marketing/dist/index.css';
import './index.css';

const rootEl = document.getElementById('root');

if (!rootEl) {
	throw new Error('could not find root element');
}

render(<App />, rootEl);
