import { Provider as DsxProvider } from '@dsx/react';
import type { LinkSourceProps, LinkSourceRecord } from '@dsx/react';
import { forwardRef } from 'react';
import styles from './App.module.css';
import {
	Outlet,
	Route,
	Link as RouterLink,
	RouterProvider,
	ScrollRestoration,
	createBrowserRouter,
	createRoutesFromElements,
} from 'react-router-dom';
import { Footer } from './components/Footer/Footer';
import { useUtmFormTracking } from './hooks/UseUtmFormTracking';
import { ErrorPage } from './pages/ErrorPage';
import { MagnoliaPage, magnoliaPageLoader } from './pages/MagnoliaPage';

const linkSources = {
	default: forwardRef<HTMLAnchorElement, LinkSourceProps>((props, ref) => (
		<RouterLink ref={ref} {...props} to={props.href || ''} />
	)),
	external: forwardRef<HTMLAnchorElement, LinkSourceProps>((props, ref) => (
		<a ref={ref} {...props} />
	)),
	none: forwardRef<HTMLSpanElement, LinkSourceProps>((props, ref) => (
		<span ref={ref} {...props} />
	)),
} as LinkSourceRecord;

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<RootLayout />}>
			<Route
				path="*"
				element={<MagnoliaPage />}
				loader={magnoliaPageLoader}
				errorElement={<ErrorPage />}
			/>
		</Route>
	)
);

export function App() {
	useUtmFormTracking();

	return (
		<DsxProvider locale="en-US" linkSources={linkSources}>
			<RouterProvider router={router} />
		</DsxProvider>
	);
}

function RootLayout() {
	return (
		<div className={styles['site-container']}>
			<main>
				<Outlet />
			</main>
			<Footer />
			<ScrollRestoration />
		</div>
	);
}
