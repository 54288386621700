import { useEffect } from 'react';
import Cookies from 'js-cookie';

export function useUtmFormTracking() {
	useEffect(() => {
		const searchParams = new URLSearchParams(document.location.search);
		const utmCampaign = {
			...(searchParams.has('utm_campaign') && {
				utm_campaign: searchParams.get('utm_campaign'),
			}),
			...(searchParams.has('utm_source') && {
				utm_source: searchParams.get('utm_source'),
			}),
			...(searchParams.has('utm_medium') && {
				utm_medium: searchParams.get('utm_medium'),
			}),
			...(searchParams.has('utm_id') && { utm_id: searchParams.get('utm_id') }),
			...(searchParams.has('utm_term') && {
				utm_term: searchParams.get('utm_term'),
			}),
			...(searchParams.has('utm_content') && {
				utm_content: searchParams.get('utm_content'),
			}),
		};

		const currentCookieValue = Cookies.get('marketing_utm_history');
		const newCookieValue = JSON.stringify(utmCampaign);
		const hasNewCampaign =
			currentCookieValue !== newCookieValue &&
			Object.keys(utmCampaign).length !== 0;

		if (hasNewCampaign) {
			const cookieExpiration = new Date(
				new Date().getTime() + 1000 * 60 * 60 * 24 * 365
			);
			const cookiePath = '/';

			Cookies.set('marketing_utm_history', newCookieValue, {
				path: cookiePath,
				expires: cookieExpiration,
			});
		}
	}, []);
}
