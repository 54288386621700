// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer-module_footer_MXt {\n\tpadding: 15px;\n\ttext-align: center;\n\tborder-top: 1px solid #d8d8d8;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,kBAAkB;CAClB,6BAA6B;AAC9B","sourcesContent":[".footer {\n\tpadding: 15px;\n\ttext-align: center;\n\tborder-top: 1px solid #d8d8d8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "Footer-module_footer_MXt"
};
export default ___CSS_LOADER_EXPORT___;
